import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private contactPurposeSubj = new BehaviorSubject<boolean>(false);
  contactPurposeSubjObs = this.contactPurposeSubj.asObservable();

  constructor(private http: HttpClient) {}

  sendMembershipForm(formBodyObj: any) {
    return this.http.post(
      `${environment.API}/user/membership-forms`,
      formBodyObj
    );
  }
  
  sendContactUsForm(formData: FormData) {
    return this.http.post(
      `${environment.API}/user/contact-us`,
      formData
    );
  }

  setContactPurposeSubj(value:boolean){
    this.contactPurposeSubj.next(value);
  }

  getUserGeolocation(){
    return this.http.get('https://ipapi.co/json/');
  }
}