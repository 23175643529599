import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DigitalLibraryService {
  searchKeyword = new BehaviorSubject('');
  searchKeywordObs = this.searchKeyword.asObservable();
  
  
  isContentLoading = new BehaviorSubject(false);
  isContentLoadingObs = this.isContentLoading.asObservable();


  constructor(private http: HttpClient) {}

  // Categories names only
  getCategories() {
    return this.http.get(`${environment.API}/user/categories/`);
  }

  // Categories including materials for each
  getCategoriesObjects() {
    return this.http.get(
      `${environment.API}/user/categories?filter={"include" : ["materials"]}`
    );
  }
  // Categories count
  getCategoriesCount() {
    return this.http.get(`${environment.API}/user/categories/count`);
  }

  // Materials
  getCategoryContentById(id: number, limit: number = 0, offset: number = 0) {
    return this.http.get(
      `${environment.API}/user/materials?filter={"where":{"categoryId":${id}}, "include":["category"],"limit":${limit},"offset":${offset}}`
    );
  }

  // Material count
  getCategoryCountById(id: number) {
    return this.http.get(
      `${environment.API}/user/materials/count?where={"categoryId":${id}}`
    );
  }

  getCategoryContentByType(id: number, limit: number = 0, offset: number = 0) {
    return this.http.get(
      `${environment.API}/user/materials?filter={"where": { "attachmentType": {"neq": "pdf"},"categoryId":${id} }, "include":["category"],"limit":${limit},"offset":${offset}}`
    );
  }

  // Search
  searchContent(keyword: string, limit: number = 0, offset: number = 0) {
    return this.http.get(
      `${environment.API}/user/materials?keyword=${keyword}&filter={"include":["category"],"limit":${limit},"offset":${offset}} `
    );
  }

  searchCount(keyword: string) {
    return this.http.get(
      `${environment.API}/user/materials/count?keyword=${keyword}`
    );
  }
}
