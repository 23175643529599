import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RiveModule } from 'ng-rive';
import { PlatformService } from '../../../shared/services/platform.service';

@Component({
  selector: 'app-consumer-journey',
  standalone: true,
  imports: [MatButtonModule, RiveModule],
  templateUrl: './consumer-journey.component.html',
  styleUrl: './consumer-journey.component.scss',
})
export class ConsumerJourneyComponent implements OnInit {
  isMobile = false;
  rivWidth = 1400;
  rivHeight = 500;
  isPlaying: boolean = false;
  // @ViewChild('riveCanvas') riveCanvas: any;
  constructor(private platform: PlatformService) {}
  ngOnInit() {
    if (this.platform.isBrowser) {
      if (document.documentElement.clientWidth <= 768) {
        this.isMobile = true;
        this.rivWidth = document.documentElement.clientWidth - 40;
        this.rivHeight = this.rivWidth * 2.125;
      }else{
        this.rivWidth = document.documentElement.clientWidth - document.documentElement.clientWidth * 0.21;
        this.rivHeight = this.rivWidth * 0.36;
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const element = document.getElementById('riveCanvas');
    const rect = element?.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;
    this.isPlaying = !!(rect && rect.top >= 0 && rect.bottom <= viewportHeight);
  }

  goToConsumerApp() {
    window.open('https://consumer.ezoah.com/', '_blank');
  }
}
