<div class="content-popup-container">
  @if (data) {
  <div
    class="close-icon"
    (click)="closePopup()"
    [attr.aria-label]="'Close button'"
  >
    <img
      loading="lazy"
      src="../../../../assets/images/x-mark_solid.svg"
      alt="close"
    />
  </div>
  <div class="content-preview">
    @if (data.attachmentType === 'img') {
    <div class="image-container attachment">
      <img loading="lazy" [src]="data.attachment" alt="image" />
    </div>
    }@else if(data.attachmentType === 'video'){
    <div
      [innerHTML]="iframeHtml"
      class="iframe-container d-flex attachment"
    ></div>
    }

    <div class="content-info">
      <div class="title" [attr.aria-label]="data.title">
        {{ data.title | truncate : 177 }}
      </div>
      <div class="description" [attr.aria-label]="data.description">
        {{ data.description | truncate : 420 }}
      </div>
    </div>
  </div>
  }
  <div class="more-content-container d-flex">
    @if(!isMoreContentLoading){
    <button
      class="previous-button navigation-btn d-flex"
      [class.btn-disabled]="isPreviousDisabled"
      (click)="navigate('previous')"
      [attr.aria-label]="'Previous button'"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
    <div class="more-content">
      <div class="content-wrapper d-flex">
        @for (item of moreContent; track $index) {
        <div
          class="more-content-img"
          (click)="showContent(item)"
          [attr.aria-label]="item.title"
          #scrollContent
        >
          <img loading="lazy" [src]="item.image" alt="content image" />
        </div>
        }
      </div>
    </div>
    <button
      class="next-button navigation-btn d-flex"
      [class.btn-disabled]="isNextDisabled"
      (click)="navigate('next')"
      [attr.aria-label]="'Next button'"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    }@else {
    <div class="d-flex spinner-container w-100">
      <app-progress-spinner spinnerColor="#55636d"></app-progress-spinner>
    </div>
    }
  </div>
</div>
