<div class="directors-board-container w-100 d-flex">
  <div class="heading-3">اعضاء مجلس الإدارة</div>
  <div class="ceo-container d-flex">
    <div class="ceo-img">
      <img loading="lazy" [src]="
          _platForm.isMobile
            ? '../../../../assets/images/DirectorsBoard/CEO-res.webp'
            : '../../../../assets/images/DirectorsBoard/CEO.webp'
        " alt="CEO - عزوة" />
    </div>
    <div class="ceo-word-container d-flex">
      <div class="ceo-word__title">كلمة رئيس مجلس الإدارة</div>
      <div class="ceo-word__body">
        أهلا بكم في موقع جمعية "عزوة" للحماية الأسرية.
        <br />
        تأسست الجمعية بقرار من الرئيس التنفيذي للمركز الوطني لتنمية القطاع غير الربحي في 1443/07/01 هـ، لتلبية الحاجة
        للعون الحقوقي في قضايا الأحوال الشخصية.
        <br />
        نحن الجمعية الأولى من نوعها في الوطن العربي، ونسعى لتوفير استشارات قانونية وترافع من خلال كوادر مؤهلة لتمكينكم من حقوقكم. 
        <br />
        نتطلع لأن نكون عونًا وسندًا لكم، ونرحب بمرئياتكم وملاحظاتكم عبر موقعنا.
      </div>
      <div class="ceo-word__name">الأستاذ/ عبداللطيف عبدالله الراجحي</div>
    </div>
  </div>
  <div class="board-duration-container">
    <div class="duration-card">
      <span class="material-symbols-outlined">event</span>
      <p class="title">تاريخ انتهاء العضوية لدورة المجلس</p>
      <p class="duration"> 2026-02-02 م </p>
    </div>
    <div class="duration-card">
      <span class="material-symbols-outlined">overview</span>
      <p class="title">مدة دورة المجلس (بالسنوات)</p>
      <p class="duration"> 4 سنوات </p>
    </div>
  </div>
  <div class="directors-container d-flex">
    @for (item of directorsBoard; track $index) {
    <div class="director-card d-flex">
      <div class="card-img d-flex">
        <img loading="lazy" [src]="
            '../../../../assets/images/DirectorsBoard/' + item.img + '.webp'
          " [alt]="item.position" />
      </div>
      <div class="card-body w-100 d-flex">
        <div class="director-title">{{ item.title }}</div>
        <div class="director-name">{{ item.name }}</div>
        <div class="director-position">{{ item.position }}</div>
      </div>
    </div>
    }
  </div>
  <div class="action-btn">
    <button mat-button class="primary-btn d-flex" (click)="openMembershipForm()">
      انضم كعضو
    </button>
  </div>
</div>