import { DialogRef } from '@angular/cdk/dialog';
import { Component, ElementRef, HostListener, Inject, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigitalLibraryService } from '../../../shared/services/digital-library.service';
import { Subscription } from 'rxjs';
import { materialItem } from '../../../shared/interfaces/library';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TruncatePipe } from '../../../shared/pipes/truncate.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ProgressSpinnerComponent } from '../../../shared/components/progress-spinner/progress-spinner.component';

@Component({
  selector: 'app-content-popup',
  standalone: true,
  imports: [
    TruncatePipe,
    MatIconModule,
    MatButtonModule,
    ProgressSpinnerComponent,
  ],
  templateUrl: './content-popup.component.html',
  styleUrl: './content-popup.component.scss',
})
export class ContentPopupComponent {
  digitalLibrarySub$!: Subscription;
  iframeHtml: SafeHtml | null = null;
  moreContent!: materialItem[];
  isNextDisabled: boolean = false;
  isPreviousDisabled: boolean = false;
  activeContentIndex: number = -1;
  isMoreContentLoading: boolean = false;
  @ViewChildren("scrollContent", { read: ElementRef }) scrollContent!: QueryList<ElementRef>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: materialItem,
    private dialogRef: DialogRef<ContentPopupComponent>,
    private _DigitalLibraryService: DigitalLibraryService,
    private sanitizer: DomSanitizer
  ) {
    if (data.attachmentType == 'video') {
      this.iframeHtml = this.sanitizer.bypassSecurityTrustHtml(data.attachment);
    }
    if (data) {
      this.isMoreContentLoading = true;
      this._DigitalLibraryService
        .getCategoryContentByType(data.categoryId, 6, 0)
        .subscribe({
          next: (content: any) => {
            this.moreContent = content;
            if (!this.doesContentExist(content)) {
              content.pop();
              this.moreContent = [this.data, ...content];
            }
          },
          complete: () => {
            this.activeContentIndex;
            this.buttonsDisabling(this.moreContent);
            this.setActiveContentIndex(this.moreContent);
            this.isMoreContentLoading = false;
          },
        });
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'ArrowLeft') {
      this.navigate('next');
    } else {
      if (event.key == 'ArrowRight') {
        this.navigate('previous');
      }
    }
  }

  closePopup() {
    this.dialogRef.close();
  }

  showContent(item: materialItem) {
    this.data = item;
    if (this.data.attachmentType == 'video') {
      this.iframeHtml = this.sanitizer.bypassSecurityTrustHtml(
        this.data.attachment
      );
    }
    this.setActiveContentIndex(this.moreContent);
    this.buttonsDisabling(this.moreContent);
  }

  buttonsDisabling(content: materialItem[]) {
    const contentLength = content.length;
    if (contentLength <= 1) {
      this.isNextDisabled = true;
      this.isPreviousDisabled = true;
    } else {
      content.forEach((element, index) => {
        if (element.id == this.data.id) {
          if (index === 0) {
            this.isPreviousDisabled = true;
            this.isNextDisabled = false;
          } else {
            if (index === contentLength - 1) {
              this.isNextDisabled = true;
              this.isPreviousDisabled = false;
            } else {
              this.isNextDisabled = false;
              this.isPreviousDisabled = false;
            }
          }
        }
      });
    }
  }

  setActiveContentIndex(content: materialItem[]) {
    let isFound = false;
    content.forEach((element, index) => {
      if (element.id == this.data.id) {
        this.activeContentIndex = index;
        isFound = true;
      }
    });
    if (!isFound) {
      this.activeContentIndex = -1;
    }
  }

  navigate(button: string) {
    if (button === 'next' && !this.isNextDisabled) {
      this.activeContentIndex++;
    }
    if (button === 'previous' && !this.isPreviousDisabled) {
      this.activeContentIndex--;
    }
    this.showContent(this.moreContent[this.activeContentIndex]);
    this.scrollContent.forEach((element, i) => {
      if (this.activeContentIndex === i) {
        element.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "end"
        });
      }
    });
  }

  doesContentExist(content: materialItem[]) {
    let contentExist = false;
    content.forEach((element: materialItem) => {
      if (element.id === this.data.id) {
        contentExist = true;
      }
    });
    return contentExist;
  }

  ngOnDestroy(): void {
    this.digitalLibrarySub$?.unsubscribe();
  }
}
