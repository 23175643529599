import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { PaginationService } from "../../services/pagination.service";
import { PaginatorSetting } from "../../interfaces/paginator-setting";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [CommonModule, MatButtonModule ,MatButtonToggleModule, MatPaginatorModule, MatIconModule, MatFormFieldModule, MatSelectModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent implements OnInit {
  @Input() width: string = "100";
  @Input() pageSize: number = 5;
  paginatorSetting: PaginatorSetting = {
    length: 0,
    pageSize: 5,
    pages: 1,
    skip: 0,
  };
  @Output() pageChange = new EventEmitter<object>();
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  pageSizeOptions = [6,10,15];
  
  limitStart = 1;
  limitEnd = 1;

  constructor(private _pagination: PaginationService) {}

  ngOnInit() {
    this.paginatorSetting.pageSize = this.pageSize;
    this._pagination.getEvent().subscribe((data) => {
      this.paginatorSetting.length = data;
      let totalPages = Math.ceil(this.paginatorSetting.length / this.pageSize);
      this.paginatorSetting.pages = totalPages <= 0 ? 1 : totalPages;
    });
    this._pagination.pageIndexObs.subscribe((index) => {
      this.paginator.pageIndex = index;
      this.limitStart = index * this.paginatorSetting.pageSize + 1;
      this.limitEnd = index * this.paginatorSetting.pageSize + this.paginatorSetting.pageSize;
    })
  }

  //when click in prev or net button
  onPageButton(event: PageEvent): void {
    let totalPages = Math.ceil(event.length / event.pageSize);
    this.paginatorSetting.pages = totalPages <= 0 ? 1 : totalPages;
    this.onPageChange();
  }

  //when click in number button
  onPageNum(i: number): void {
    this.paginator.pageIndex = i;
    this.onPageChange();
  }

  //get data of this page
  onPageChange(): void {
    this.pageChange.emit({
      size: this.pageSize,
      index: this.paginator.pageIndex * this.pageSize,
    });
    this.limitStart = this.paginator.pageIndex * this.paginatorSetting.pageSize + 1;
    this.limitEnd = this.paginator.pageIndex * this.paginatorSetting.pageSize + this.paginatorSetting.pageSize;
  }

  changePageSize(event:any){
    this._pagination.pageSize.next(event.value);
    // let totalPages = Math.ceil(this.paginatorSetting.length / event.value);
    // this.paginatorSetting.pages = totalPages <= 0 ? 1 : totalPages;
  }
}
