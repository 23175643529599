import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { PlatformService } from '../../../shared/services/platform.service';
import { ScrollService } from '../../../shared/services/scroll.service';

@Component({
  selector: 'app-hero',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './hero.component.html',
  styleUrl: './hero.component.scss',
})
export class HeroComponent implements OnInit {
  @ViewChild('subSlogan') subSlogan!: ElementRef;
  isVisible: boolean = true;

  constructor(private platform: PlatformService, private _ScrollService: ScrollService) {}

  ngOnInit() {
    if (this.platform.isBrowser) {
      setTimeout(() => {
        this.redoAnimation();
      }, 100);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    const element = document.getElementById('subSlogan');
    const rect = element?.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;
    if (!this.isVisible && rect && rect.top >= 0 && rect.bottom <= viewportHeight) {
      this.redoAnimation();
    } 
    this.isVisible = !!(rect && rect.top >= 0 && rect.bottom <= viewportHeight );
  }

  redoAnimation() {
    this.subSlogan.nativeElement.classList.remove('animation');
    setTimeout(() => {
      this.subSlogan.nativeElement.classList.add('animation');
    }, 100);
  }

  scrollToSection(sectionId: string) {
    setTimeout(() => {
      this._ScrollService.sendScrollEvent(sectionId);
    }, 500);
  }
}
