<div class="statistics-container w-100">
    <div class="cards-container d-flex">
        <div class="card d-flex">
            <div class="card__title">متطوع</div>
            <div class="card__number">{{volunteers | number}}</div>
        </div>
        <div class="card d-flex">
            <div class="card__title">مستفيد</div>
            <div class="card__number">{{users  | number}}</div>
        </div>
        <div class="card d-flex">
            <div class="card__title">شريك</div>
            <div class="card__number">{{partners | number}}</div>
        </div>
        <div class="card d-flex">
            <div class="card__title">قضايا</div>
            <div class="card__number">{{cases | number}}</div>
        </div>
        <div class="card d-flex">
            <div class="card__title">إستشارات</div>
            <div class="card__number">{{inquiries | number}}</div>
        </div>
    </div>
</div>