import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progress-spinner.component.html',
  styleUrl: './progress-spinner.component.scss'
})
export class ProgressSpinnerComponent {
  @Input() spinnerColor: string = "#fff"
  @Input() spinnerScale: string = "1"
}
