<div class="snackbar-center-container">
  <div class="snackbar-container d-flex">
    <div class="icon">
      <!-- <img loading='lazy' src="../../../../assets/images/Success-icon.svg" alt="تهانينا"> -->
      <img loading="lazy" [src]="data.imageUrl" alt="تهانينا" />
    </div>
    <div class="title">تهانينا</div>
    <div class="body success">تم التقديم بنجاح</div>
    <div class="body">سوف يتم التحقق من البيانات والتواصل معك قريبًا</div>
  </div>
</div>
