<div class="content-item d-flex w-100" [class.evenBg]="index % 2 !== 0">
  <div class="issued-date-resp d-flex">
    <div class="icon">
      <img
        loading="lazy"
        src="../../assets/images/calendar-days_solid.svg"
        alt="date"
      />
    </div>
    {{ !!item.createdAt ? (item.createdAt | formateDate) : "1/6/2024" }}
  </div>
  <div class="content-img">
    <img loading="lazy" [src]="item.image" alt="image" />
  </div>
  <div class="content-body d-flex">
    <div class="title">{{ item.title | truncate : 72 }}</div>
    <div class="description">{{ item.description | truncate : 155 }}</div>
  </div>
  <div class="content-type d-flex">
    @if(item.attachmentType === 'img'){
    <div class="icon d-flex">
      <img
        loading="lazy"
        src="../../assets/images/type-photo.svg"
        alt="image"
      />
    </div>
    <div class="type">صورة</div>
    } @if(item.attachmentType === 'video'){
    <div class="icon d-flex">
      <img
        loading="lazy"
        src="../../assets/images/type-video.svg"
        alt="video"
      />
    </div>
    <div class="type">فيديو</div>
    } @if(item.attachmentType === 'pdf'){
    <div class="icon d-flex">
      <img
        loading="lazy"
        src="../../assets/images/type-document.svg"
        alt="document"
      />
    </div>
    <div class="type">ملف</div>
    }
  </div>
  <div class="content-actions d-flex">
    <div class="issued-date d-flex">
      <div class="icon">
        <img
          loading="lazy"
          src="../../assets/images/calendar-days_solid.svg"
          alt="date"
        />
      </div>
      {{ !!item.createdAt ? (item.createdAt | formateDate) : "1/6/2024" }}
    </div>
    <div class="action-btns d-flex w-100">
      @if (item.attachmentType === 'pdf') {
      <button
        mat-button
        class="action-btn action-btn-sm"
        (click)="downloadFile(item)"
      >
        عرض
      </button>
      @if (isFileDownloading && downloadingFileId === item.id) {
      <button mat-button class="action-btn action-btn-sm download-btn" disabled>
        <app-progress-spinner spinnerColor="#55636d"></app-progress-spinner>
      </button>
      }@else {
      <button
        mat-button
        class="action-btn action-btn-sm download-btn"
        (click)="downloadPdf(item)"
      >
        تحميل
      </button>
      } }@else { @if (item.attachmentType === 'video') {
      <button
        mat-button
        class="action-btn action-btn-lg"
        (click)="openContentPopup(item)"
      >
        عرض
      </button>
      } @else {
      <button
        mat-button
        class="action-btn action-btn-sm"
        (click)="openContentPopup(item)"
      >
        عرض
      </button>
      <button
        mat-button
        class="action-btn action-btn-sm download-btn"
        (click)="downloadFile(item)"
      >
        تحميل
      </button>
      } }
    </div>
  </div>
</div>
