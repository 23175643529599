<div class="search-container">
  <form class="search d-flex" role="search" id="searchForm" #searchForm>
    <mat-form-field appearance="fill" class="d-flex">
      <div class="search-icon">
        <img
          loading="lazy"
          src="../../../../assets/images/search-icon.svg"
          alt="search"
        />
      </div>
      <input
        matInput
        placeholder="ابحث هنا .."
        ngModel
        #searchInput="ngModel"
        name="searchInput"
        (keyup)="onSearch(searchInput.value.trim())"
        appTrim
      />
    </mat-form-field>
  </form>
</div>
