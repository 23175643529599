<div class="home-container" id="homeSection">
  <app-hero id="heroSection"></app-hero>
  <app-statistics id="statisticsSection"></app-statistics>
  <app-about id="aboutSection" appIntersectionObserver
    (visibilityChange)="onVisibilityChange($event, 'aboutSection')"></app-about>
  <app-consumer-journey id="journeySection" appIntersectionObserver
    (visibilityChange)="onVisibilityChange($event, 'journeySection')"></app-consumer-journey>
  <app-directors-board id="directorsSection" appIntersectionObserver
    (visibilityChange)="onVisibilityChange($event, 'directorsSection')"></app-directors-board>
  <app-initiatives id="initiativesSection" appIntersectionObserver
    (visibilityChange)="onVisibilityChange($event, 'initiativesSection')"></app-initiatives>
  <app-digital-library id="librarySection" appIntersectionObserver
    (visibilityChange)="onVisibilityChange($event, 'librarySection')"></app-digital-library>
  <app-contact-us id="contactUsSection" appIntersectionObserver
    (visibilityChange)="onVisibilityChange($event, 'contactUsSection')"></app-contact-us>
  <app-slider />
</div>