<div class="digitalLibrary-container w-100">
  <div class="heading-3" id="libraryTitle">المكتبة الرقمية</div>
  <div class="search-bar w-100">
    <app-search></app-search>
  </div>
  @if(!isSearching){
  <div class="categories-container">
    <mat-chip-set aria-label="categories">
      @for (category of !isMobile? categories.slice(0, 6): categories.slice(0,
      2) ; track $index) {
      <div [class.activeChip]="category.title === activeCategory.title" (click)="viewCategory(category)">
        <mat-chip> {{ category.title | truncate : 24 }} </mat-chip>
      </div>
      } @if (categoriesCount > 6) {
      <div [matMenuTriggerFor]="moreCategories">
        <mat-chip class="d-flex">
          المزيد
          <mat-icon class="material-symbols-outlined">keyboard_arrow_down</mat-icon>
        </mat-chip>
      </div>
      }
    </mat-chip-set>
  </div>
  }
  <div class="content-container w-100">
    @if(isContentLoading){
    <div class="spinner-container d-flex">
      <app-progress-spinner spinnerScale="4" spinnerColor="#55636d"></app-progress-spinner>
    </div>
    }@else { @for (item of categoryContent; track $index) {
    <app-card [item]="item" class="content-item" [index]="$index"></app-card>
    }@empty { @if(isSearching){
    <div class="emptyState d-flex w-100">
      <div class="emptyState-img">
        <img loading="lazy" src="../../assets/images/emptyState-search.svg" alt="empty " />
      </div>
      <div class="info">لا يوجد نتائج لهذا البحث</div>
    </div>
    }@else {
    <div class="emptyState d-flex w-100">
      <div class="emptyState-img">
        <img loading="lazy" src="../../assets/images/emptyState-category.svg" alt="empty" />
      </div>
      <div class="info">لا يوجد بيانات بعد، المحتوى تحت الإعداد</div>
    </div>
    } } @if(categoryContent.length > 0){
    <div class="content-pagination">
      <app-pagination (pageChange)="onPageChange($event)" [pageSize]="paginatorSetting.pageSize"></app-pagination>
    </div>
    } }
  </div>

  <mat-menu #moreCategories="matMenu" yPosition="below">
    @for (category of !isMobile? categories.slice(6) : categories.slice(2);
    track $index) {
    <div class="more-menu-item" (click)="SwapAndViewCategory(category, $index)">
      {{ category.title }}
    </div>
    }
  </mat-menu>
</div>

<!-- libarry soon -->
<!-- <div class="digitalLibrary-container as-soon_container   w-100">
  <div class="d-flex">
    <div class="as-soon">
      <div class="header">
        <h6 class='soon heading-7'>
          قريباً
        </h6>
        <h5 class="heading-3">
          المكتبة الرقمية
        </h5>
      </div>
      <div class="content">
        <span class="heading-1">
          نعمل بإستمرار
        </span>
        <div class="heading-1">
          لجعل تجربتك مع خدمات الجمعية أسهل
        </div>
      </div>
    </div>

    <div class="library-soon">
      <img loading="lazy" src="../../assets/images/library-soon.svg" alt="library-soon" />
    </div>
  </div>

  <div class="register-no text-tiny">
    رقم السجل: 1010788935
  </div>
</div> -->
