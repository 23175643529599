<div class="hero-container w-100 d-flex" id="heroSection">
  <div class="hero__info">
    جمعية أهلية مسجلة لدى المركز الوطني لتنمية القطاع غير الربحي برقم (2232) وتاريخ 1443/07/01 هـ
  </div>
  <h2 class="hero__slogan-main">
    <span>لست وحدك، </span>
    <span> نحن معك</span>
  </h2>
  <h1 class="hero__slogan-sub d-flex">
    <div id="subSlogan" #subSlogan>
      عزوة وسند
    </div>
    <!-- {{currentText}} -->
  </h1>
  <button mat-button class="primary-btn hero__action-btn d-flex" (click)="scrollToSection('contactUsSection')">
    تواصل
  </button>
  <div class="hero__registry d-flex">
    <div> رقم السجل: <span>
        1010788935</span> </div>
    <div> ( تحت الإشراف الفني لوزارة العدل ) </div>
  </div>
</div>