import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(
    value: string | undefined,
    maxLength: number,
    lang?: string
  ): string {
    if (value) {
      if (value.length > maxLength) {
        return lang == 'en'
          ? '...' + value.substring(0, maxLength - 3)
          : value.substring(0, maxLength - 3) + '...';
      } else {
        return value;
      }
    } else return '';
  }
}
