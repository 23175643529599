import { Component, OnDestroy, OnInit } from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import { InitiativesService } from '../../../shared/services/initiatives.service';
import { Subscription } from 'rxjs';
import { PlatformService } from '../../../shared/services/platform.service';

@Component({
  selector: 'app-initiatives',
  standalone: true,
  imports: [MatExpansionModule],
  templateUrl: './initiatives.component.html',
  styleUrl: './initiatives.component.scss'
})
export class InitiativesComponent implements OnInit, OnDestroy{
  panelOpenState = false;
  InitiativesServiceSub$!: Subscription;
  initiatives!: any[];
  constructor(private _InitiativesService: InitiativesService, private platform: PlatformService){}

  ngOnInit() {
    if(this.platform.isBrowser){
      this.InitiativesServiceSub$ = this._InitiativesService.getInitiatives().subscribe((res:any) => {
        this.initiatives = res;
      })
    }
  }

  ngOnDestroy() {
    this.InitiativesServiceSub$?.unsubscribe();
  }
}
