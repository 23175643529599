<div class="journey-container d-flex">
  <div class="heading-3">رحلتك كمستفيد</div>
  <button
    mat-button
    class="action-btn d-flex primary-btn"
    (click)="goToConsumerApp()"
  >
    <div class="d-flex">
      بوابة الخدمات
      <span class="icon d-flex">
        <img
          loading="lazy"
          src="../../../../assets/images/arrow-up-left_mini.svg"
          alt="الخدمات"
        />
      </span>
    </div>
  </button>
  @if(!isMobile){
  <div class="rive-container w-100 d-flex">
    <canvas
      riv="ozwaa web loop"
      [width]="rivWidth"
      [height]="rivHeight"
      id="riveCanvas"
    >
      <riv-animation name="Timeline 1" [play]="isPlaying"></riv-animation>
    </canvas>
  </div>
  }@else {
  <div class="rive-container w-100 d-flex">
    <canvas
      riv="ozwaa respon loop"
      [width]="rivWidth"
      [height]="rivHeight"
      id="riveCanvas"
    >
      <riv-animation name="Timeline 1" play></riv-animation>
    </canvas>
  </div>
  }
</div>
