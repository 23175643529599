import { Component, Input } from '@angular/core';
import { TruncatePipe } from '../../../shared/pipes/truncate.pipe';
import { materialItem } from '../../../shared/interfaces/library';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { ContentPopupComponent } from '../content-popup/content-popup.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ProgressSpinnerComponent } from '../../../shared/components/progress-spinner/progress-spinner.component';
import { FormateDatePipe } from '../../../shared/pipes/formate-date.pipe';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [
    TruncatePipe,
    FormateDatePipe,
    MatButtonModule,
    ContentPopupComponent,
    ProgressSpinnerComponent,
  ],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
})
export class CardComponent {
  @Input('item') item!: materialItem;
  @Input('index') index!: number;
  isFileDownloading: boolean = false;
  downloadingFileId!: number;
  constructor(private _dialog: MatDialog) {}

  // Download image or open PDF
  downloadFile(item: materialItem) {
    if (item.attachmentType === 'img' || item.attachmentType === 'pdf') {
      window.open(item.attachment);
    }
  }

  // Download PDF
  downloadPdf(item: materialItem) {
    this.isFileDownloading = true;
    this.downloadingFileId = item.id;
    fetch(item.attachment)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, item.title);
        this.isFileDownloading = false;
      })
      .catch((error) => {
        console.error('Error downloading audio:', error);
        this.isFileDownloading = false;
      });
  }

  openContentPopup(item: materialItem) {
    this._dialog.open(ContentPopupComponent, {
      scrollStrategy: new NoopScrollStrategy(),
      data: item,
      width: '90.7%',
    });
  }
}
