import { Component, OnInit } from '@angular/core';
import { HeroComponent } from './components/hero/hero.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { AboutComponent } from './components/about/about.component';
import { ConsumerJourneyComponent } from './components/consumer-journey/consumer-journey.component';
import { DirectorsBoardComponent } from './components/directors-board/directors-board.component';
import { InitiativesComponent } from './components/initiatives/initiatives.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SliderComponent } from './components/slider/slider.component';
import { Subscription } from 'rxjs';
import { ScrollService } from '../shared/services/scroll.service';
import { PlatformService } from '../shared/services/platform.service';
import { IntersectionObserverDirective } from '../shared/directives/intersection-observer.directive';
import { DigitalLibraryComponent } from '../digital-library/digital-library.component';
import { DigitalLibraryService } from '../shared/services/digital-library.service';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [
    HeroComponent,
    StatisticsComponent,
    AboutComponent,
    ConsumerJourneyComponent,
    DirectorsBoardComponent,
    InitiativesComponent,
    ContactUsComponent,
    SliderComponent,
    DigitalLibraryComponent,
    IntersectionObserverDirective,
  ],
})
export class HomeComponent implements OnInit {
  scrollSub$!: Subscription;

  constructor(
    private _ScrollService: ScrollService,
    private platfrom: PlatformService,
    private _DigitalLibraryService: DigitalLibraryService
  ) {}

  ngOnInit() {
    if (this.platfrom.isBrowser) {
      this.scrollSub$ = this._ScrollService.scrollSubjectObs.subscribe(
        (elementId) => {
          if (elementId == 'contactUsSection') {
            this._DigitalLibraryService.isContentLoadingObs.subscribe(
              (isLoading) => {
                if (isLoading == false) {
                  setTimeout(() => {
                    this.scrollIntoView(elementId);
                  }, 100);
                }
              }
            );
          } else {
            this.scrollIntoView(elementId);
          }
        }
      );
    }
  }

  scrollIntoView(elementId: string) {
    if (elementId != '') {
      const targetElement = document.body.querySelector(`#${elementId}`);
      if (targetElement) {
        const targetElementRect = targetElement.getBoundingClientRect();
        window.scroll({
          behavior: 'smooth',
          top: targetElementRect.top + window.scrollY - 60,
        });
      }
    }
  }

  onVisibilityChange(isVisible: boolean, section: string): void {
    if (isVisible) {
      this._ScrollService.sectionInViewSubject.next(section);
    }
  }
}
