import { Component, OnInit } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { TrimDirective } from '../../../shared/directives/trim.directive';
import { Subject, debounceTime } from 'rxjs';
import { DigitalLibraryService } from '../../../shared/services/digital-library.service';
import { PlatformService } from '../../../shared/services/platform.service';
@Component({
  selector: 'app-search',
  standalone: true,
  imports: [FormsModule,MatInputModule, MatFormFieldModule, TrimDirective],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit{
  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 300; // Set the debounce time (in milliseconds)

  constructor(private _DigitalLibraryService: DigitalLibraryService, private platform: PlatformService,){}
  
  ngOnInit() {
    if(this.platform.isBrowser){
      this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue) => {
          this._DigitalLibraryService.searchKeyword.next(searchValue);
      });
    } 
  }

  onSearch(searchValue: string) {
    this.searchSubject.next(searchValue.trim());
  }
}
