<div class="initiatives-container">
    <div class="heading-3"> مبادرات عزوة </div>
    <div class="initiatives-accordion">
        <mat-accordion>
            @for (item of initiatives; track $index) {
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{item.title}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{item.description}}</p>
                </mat-expansion-panel>
            }
        </mat-accordion>
    </div>
</div>