import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MembershipFormComponent } from '../../../shared/components/membership-form/membership-form.component';
import { PlatformService } from '../../../shared/services/platform.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-directors-board',
  standalone: true,
  imports: [MatButtonModule],
  templateUrl: './directors-board.component.html',
  styleUrl: './directors-board.component.scss',
})
export class DirectorsBoardComponent {
  directorsBoard = [
    {
      img: 'Mrs-Noaf',
      title: 'صاحبة السمو ',
      name: 'نوف بنت محمد بن عبد الله آل سعود',
      position: 'نائب الرئيس مجلس الإدارة',
    },
    {
      img: 'Mr-Abdullah',
      title: 'الاستاذ',
      name: 'عبدالله بن عبدالكريم المنيف',
      position: 'رجل أعمال',
    },
    {
      img: 'Mr-Alaa',
      title: 'الاستاذ',
      name: 'علاء بن حجاج بن عبدالله العريني',
      position: 'المشرف المالي ',
    },
    {
      img: 'Dr-Wafaa',
      title: 'الدكتورة ',
      name: 'وفاء بنت عبدالله بن رشيد الرشيد',
      position: 'الرئيس التنفيذي  ',
    },
    {
      img: 'Dr-Abdullah',
      title: 'الدكتور',
      name: 'عبدالله بن ناصر بن محمد المشعل',
      position: 'عضو هيئة التدريس بجامعة الامام ',
    },
    {
      img: 'Mrs-Manal',
      title: 'الاستاذة',
      name: 'منال عبدالرزاق يوسف خان',
      position:
        'استشارات اقتصادية وتخطيط وتطوير الاعمال وإدارة مشاريع ومبادرات',
    },
    {
      img: 'Mr-Faisl',
      title: 'الاستاذ',
      name: 'فيصل بن عبدالعزيز بن ابراهيم المغيليث',
      position: 'رئيس تنفيذي / أمين عام',
    },
    {
      img: 'Mr-Soliman',
      title: 'الاستاذ',
      name: 'سليمان عبد الرحمن محمد السحيباني',
      position: 'اعمال حره',
    },
    {
      img: 'Mrs-Hanan',
      title: 'الاستاذة',
      name: 'حنان عبد الله محمد السماري',
      position: 'عضو مجلس الشورى',
    },
    {
      img: 'Mr-Abdulmohsen',
      title: 'الشيخ',
      name: 'عبدالمحسن محمد عبدالله العثمان',
      position: 'رئيس تنفيذي',
    },
  ];

  constructor(private _dialog: MatDialog, public _platForm: PlatformService) {}

  openMembershipForm() {
    this._dialog.open(MembershipFormComponent, {
      position: { top: '3rem' },
      scrollStrategy: new NoopScrollStrategy(),
      maxWidth: '90vw',
    });
  }
}
