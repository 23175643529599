import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgForm, NgModel } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TrimDirective } from '../../../shared/directives/trim.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { InfoSnackbarComponent } from '../../../shared/snackbars/info-snackbar/info-snackbar.component';
import { GenerateFormDataService } from '../../../shared/services/generate-form-data.service';
import { ContactService } from '../../../shared/services/contact.service';
import { ProgressSpinnerComponent } from '../../../shared/components/progress-spinner/progress-spinner.component';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PlatformService } from '../../../shared/services/platform.service';
import { TemplateFormValidatorDirective } from '../../../shared/directives/template-form-validator.directive';

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    TrimDirective,
    CommonModule,
    ProgressSpinnerComponent,
    TemplateFormValidatorDirective,
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent implements OnInit, OnDestroy {
  isSubmitEnabled: boolean = true;
  @ViewChild('contactUsForm') contactUsForm!: NgForm;
  @ViewChild('attachmentFilesInput') attachmentFilesInput!: ElementRef;
  attachmentFiles: File[] | null | undefined = [];
  MAXIMUM_IMAGE_SIZE: number = 2097152;
  isFilesInvalid: boolean | undefined = false;
  files!: File[];
  contactForJob: boolean = false;
  ContactServiceSub$!: Subscription;
  isMobile = false;
  contactReason = 'jobApplication';

  constructor(
    private snackbar: MatSnackBar,
    private _generateFormData: GenerateFormDataService,
    private _ContactService: ContactService,
    private platform: PlatformService
  ) {}

  ngOnInit(): void {
    this._ContactService.contactPurposeSubjObs.subscribe((value: any) => {
      this.contactForJob = value;
      if (!!value) {
        this.contactReason = 'jobApplication';
      }
    });
    if (this.platform.isBrowser) {
      if (document.documentElement.clientWidth <= 768) {
        this.isMobile = true;
      }
    }
  }

  validateWhiteSpacesInput(value: any) {
    const isWhitespace = (value || '').trim().length === 0;
    return !isWhitespace;
  }

  triggerUploadFiles(): void {
    this.attachmentFilesInput.nativeElement.click();
  }

  //upload files to ready to submited with inquiry
  uploadFiles(event: any): void {
    this.files = event.target.files;
    this.attachmentFiles?.push(...this.files);
    this.isFilesInvalid = [...this.files]?.every((file, index) => {
      return file.size >= this.MAXIMUM_IMAGE_SIZE;
    });

    if (this.isFilesInvalid) {
      this.openInfoSnackbar(' لا يزيد حجم المرفق عن 2MG ', 'warning');
    }

    this.attachmentFiles = this.attachmentFiles?.filter((file, index) => {
      if (index >= 10) {
        this.openInfoSnackbar('حد أقصى 10 ملفات', 'warning');
      }
      return file.size <= this.MAXIMUM_IMAGE_SIZE && index < 10;
    });

    this.attachmentFilesInput.nativeElement.value = '';
  }

  //remove file from selected files before submitting
  removeFile(deletedFile: File): void {
    this.attachmentFiles = this.attachmentFiles?.filter(
      (file) => file != deletedFile
    );
  }

  submitContactUsForm() {
    this.isSubmitEnabled = false;
    const formData = this._generateFormData.generateFormData({
      firstName: this.contactUsForm.value.firstName,
      email: this.contactUsForm.value.emailContact,
      extraMsg: this.contactUsForm.value.message,
      purpose: this.contactReason,
      ...this.attachmentFiles,
    });
    if (!!this.contactUsForm.value.secondName && this.contactUsForm.value.secondName.length > 0) {
      formData.append('secondName', this.contactUsForm.value.secondName);
    }
    this.ContactServiceSub$ = this._ContactService
      .sendContactUsForm(formData)
      .subscribe({
        next: () => {
          this.isSubmitEnabled = true;
          this.openInfoSnackbar('تم إرسال رسالتك بنجاح', 'success');
          this.contactUsForm.resetForm();
          this.attachmentFiles = [];
          setTimeout(() => {
            this.contactReason = 'jobApplication';
          }, 100);
        },
        error: () => {
          this.isSubmitEnabled = true;
        },
      });
  }

  openInfoSnackbar(msg: string, type: string) {
    this.snackbar.openFromComponent(InfoSnackbarComponent, {
      duration: 1500,
      data: {
        message: msg,
        type: type,
      },
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  navigateToPlatforms(type: string) {
    const URL =
      type === 'whatsApp'
        ? `https://wa.me/+966504461988`
        : type === 'tiktok'
        ? 'https://www.tiktok.com/@ezoahksa'
        : type === 'instagram'
        ? `https://www.instagram.com/ezoahksa`
        : type === 'x'
        ? `https://x.com/EzoahKSA`
        : type === 'snapchat'
        ? `https://www.snapchat.com/add/ezoah_ksa`
        : '';

    if (URL !== '') {
      window.open(URL, '_blank');
    }
  }

  downloadApp(store: string) {
    if (store === 'appStore') {
      window.location.replace(environment.APP_STORE_URL);
    } else if (store === 'googlePlay') {
      window.location.replace(environment.GOOGLE_PLAY_URL);
    }
  }

  touchField(field: NgModel) {
    field.control.markAsTouched();
  }

  ngOnDestroy(): void {
    this.ContactServiceSub$?.unsubscribe();
  }
}
