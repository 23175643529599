import { Component, inject, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { PlatformService } from '../../../shared/services/platform.service';

@Component({
  selector: 'app-about',
  standalone: true,
  imports: [MatTabsModule],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
})
export class AboutComponent implements OnInit {
  isMobile = false;
  platform = inject(PlatformService);
  ngOnInit(): void {
    if (this.platform.isBrowser) {
      if (document.documentElement.clientWidth <= 768) {
        this.isMobile = true;
      }
    }
  }
}
