<div class="slider" #slider>
  @for (logoSet of repeatedLogos; track $index) {
  <div class="slide-track">
    @for (logo of logos; track $index) {
    <div class="item">
      <img loading="lazy" class="logo" [src]="logo.image" [alt]="logo.alt" />
    </div>
    }
  </div>
  }
</div>
