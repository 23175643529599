import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InitiativesService {

  constructor(private http: HttpClient) { }

  getInitiatives(){
    return this.http.get(`${environment.API}/user/initiatives?filter[limit]=10&filter[offset]=0`)    
  }
}
