import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  sectionInViewSubject = new BehaviorSubject<string>('aboutSection');
  sectionInViewSubjectObs = this.sectionInViewSubject.asObservable();
  
  private scrollSubject = new BehaviorSubject<string>('');
  scrollSubjectObs = this.scrollSubject.asObservable();

  sendScrollEvent(elementId: string) {
    this.scrollSubject.next(elementId);
    // this.sectionInViewSubject.next(elementId);
  }

}
