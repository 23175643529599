import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formateDate',
  standalone: true
})
export class FormateDatePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return value;

    const date = new Date(value);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are zero-indexed in JavaScript
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  }
}
