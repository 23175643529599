import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TemplateFormValidatorDirective } from '../../directives/template-form-validator.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogRef } from '@angular/cdk/dialog';
import { ContactService } from '../../services/contact.service';
import { Subscription } from 'rxjs';
import { SubmitSuccessComponent } from '../../snackbars/submit-success/submit-success.component';
import { DateAdapter } from '@angular/material/core';
import { TrimDirective } from '../../directives/trim.directive';
import { ImagePreloaderServiceService } from '../../services/image-preloader-service.service';
import { ProgressSpinnerComponent } from '../progress-spinner/progress-spinner.component';

@Component({
  selector: 'app-membership-form',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    TemplateFormValidatorDirective,
    TrimDirective,
    ProgressSpinnerComponent,
  ],
  templateUrl: './membership-form.component.html',
  styleUrl: './membership-form.component.scss',
})
export class MembershipFormComponent implements OnDestroy {
  @ViewChild('membershipForm') membershipForm!: NgForm;
  nidNumber: any;
  phoneNumberShow: any = '';
  phoneNumber!: string;
  idLength: boolean = false;
  membershipTypeStr: string = '';
  SaudiCities = [
    {
      AR: 'الرياض',
      ENG: 'Riyadh',
    },
    {
      AR: 'مكـة المكرمة',
      ENG: 'Mecca',
    },
    {
      AR: 'المدينة المنورة',
      ENG: 'Medina',
    },
    {
      AR: 'القصيم',
      ENG: 'Al-kossiem',
    },
    {
      AR: 'المنطقة الشرقية',
      ENG: 'Al-Mantaqa Elshrqiea',
    },
    {
      AR: 'عسير',
      ENG: 'Aasser',
    },
    {
      AR: 'تبــوك',
      ENG: 'Tabok',
    },
    {
      AR: 'حائل',
      ENG: 'Hail',
    },
    {
      AR: 'الحدود الشمالية',
      ENG: 'Elhodod Elshmalya',
    },
    {
      AR: 'جازان',
      ENG: 'Gazan',
    },
    {
      AR: 'نجران',
      ENG: 'Najran',
    },
    {
      AR: 'الباحة',
      ENG: 'Al-Bahah',
    },

    {
      AR: 'الجـوف',
      ENG: 'Al-Gof',
    },
    {
      AR: 'أخرى',
      ENG: 'other',
    },
  ];
  // SaudiCities = [
  //   {
  //     AR: 'الرياض',
  //     ENG: 'Riyadh',
  //   },
  //   {
  //     AR: 'جدة',
  //     ENG: 'Jiddah',
  //   },
  //   {
  //     AR: 'مكة',
  //     ENG: 'Mecca',
  //   },
  //   {
  //     AR: 'المدينة المنورة',
  //     ENG: 'Medina',
  //   },
  //   {
  //     AR: 'الدمام',
  //     ENG: 'Al-Dammam',
  //   },
  //   {
  //     AR: 'تبوك',
  //     ENG: 'Tabuk',
  //   },
  //   {
  //     AR: 'بريدة',
  //     ENG: 'Buraydah',
  //   },
  //   {
  //     AR: 'الطائف',
  //     ENG: 'Al-Taaif',
  //   },
  //   {
  //     AR: 'خميس مشيط',
  //     ENG: 'Khamis Mushayt',
  //   },
  //   {
  //     AR: 'حائل',
  //     ENG: 'Hail',
  //   },
  //   {
  //     AR: 'الهفوف',
  //     ENG: 'Al-Hufuf',
  //   },
  //   {
  //     AR: 'الخُبَر',
  //     ENG: 'Al-Khubar',
  //   },
  //   {
  //     AR: 'نجران',
  //     ENG: 'Najran',
  //   },
  //   {
  //     AR: 'أبها',
  //     ENG: 'Abha',
  //   },
  //   {
  //     AR: 'ينبع',
  //     ENG: 'Yanbu',
  //   },
  //   {
  //     AR: 'سكاكا',
  //     ENG: 'Sakaka',
  //   },
  //   {
  //     AR: 'عرعر',
  //     ENG: 'Araar',
  //   },
  //   {
  //     AR: 'جيزان',
  //     ENG: 'Jizan',
  //   },
  //   {
  //     AR: 'الباحة',
  //     ENG: 'Al-Bahah',
  //   },
  //   {
  //     AR: 'القطيف',
  //     ENG: 'Al-Qatif',
  //   },
  //   {
  //     AR: 'رأس تنورة',
  //     ENG: 'Ras Tanura',
  //   },
  //   {
  //     AR: 'بقيق',
  //     ENG: 'Buqayq',
  //   },
  //   {
  //     AR: 'الجوف',
  //     ENG: 'Al-Jawf',
  //   },
  //   {
  //     AR: 'الخرج',
  //     ENG: 'Al-Kharj',
  //   },

  //   {
  //     AR: 'الظهران',
  //     ENG: 'Dhahran',
  //   },
  //   {
  //     AR: 'مدينة الملك خالد العسكرية',
  //     ENG: 'King Khalid Military City',
  //   },
  //   {
  //     AR: 'أخرى',
  //     ENG: 'other',
  //   },
  // ];

  ContactServiceSub$!: Subscription;
  userIp!: string;
  isSubmitEnabled: boolean = true;
  today = new Date();
  successImageUrl: string = './assets/images/Success-icon.svg';
  isImageLoaded: boolean = false;
  constructor(
    private dialogRef: DialogRef<MembershipFormComponent>,
    private _ContactService: ContactService,
    private snackbar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private _imagePreloaderService: ImagePreloaderServiceService
  ) {
    this.dateAdapter.setLocale('ar-ar');
  }

  //validation function on saudi national id
  validateSAID(id: any) {
    id = id?.trim();
    if (Number(id) === null) {
      return false;
    }
    if (id.length !== 10) {
      return false;
    }
    const type = id.substr(0, 1);
    if (type !== '2' && type !== '1') {
      return false;
    }
    let sum = 0;
    for (let i = 0; i < 10; i++) {
      if (i % 2 === 0) {
        const ZFOdd = String('00' + String(Number(id.substr(i, 1)) * 2)).slice(
          -2
        );
        sum += Number(ZFOdd.substr(0, 1)) + Number(ZFOdd.substr(1, 1));
      } else {
        sum += Number(id.substr(i, 1));
      }
    }
    return sum % 10 !== 0 ? false : true;
  }

  //valid pattern
  validNumber(value: any) {
    if (value.control.value != null) {
      this.phoneNumber = value.control.value.toString().replace(/^0+/, '');
    }
  }

  validateFullName(formValue: any) {
    let length = 0;
    const nameArr = formValue.trim().split(' ');
    nameArr.forEach((element: string) => {
      if (element !== '') length++;
    });
    return length >= 4 ? true : false;
  }

  checkPhoneLength(value: any) {
    const toString = '' + value.control.value;
    this.idLength = toString.length === 9;
  }

  submitMembership() {
    this.isSubmitEnabled = false;
    let formData: any = {
      name: this.membershipForm.value.fullName.trim(),
      birthDate: this.membershipForm.value.birthdate,
      gender: this.membershipForm.value.gender,
      nid: this.membershipForm.value.nid.toString(),
      phone: '+966' + this.membershipForm.value.phone,
      membershipType: this.membershipForm.value.membershipType,
    };

    if (
      this.membershipForm.value.email &&
      this.membershipForm.value.email.length > 0
    ) {
      formData.email = this.membershipForm.value.email;
    }
    if (
      this.membershipForm.value.city &&
      this.membershipForm.value.city.length > 0
    ) {
      formData.city = this.membershipForm.value.city;
    }
    if (
      this.membershipForm.value.currentEmployer &&
      this.membershipForm.value.currentEmployer.trim().length > 0
    ) {
      formData.currentEmployer =
        this.membershipForm.value.currentEmployer.trim();
    }
    if (
      this.membershipForm.value.jobTitle &&
      this.membershipForm.value.jobTitle.trim().length > 0
    ) {
      formData.jobTitle = this.membershipForm.value.jobTitle.trim();
    }

    this.ContactServiceSub$ = this._ContactService
      .sendMembershipForm(formData)
      .subscribe({
        next: (res) => {
          // this.snackbar.openFromComponent(SubmitSuccessComponent, {
          //   duration: 1500,
          //   horizontalPosition: 'center',
          //   verticalPosition: 'top',
          // });
          this.preloadAndShowSnackbar().then(() => {
            this.cancelSubmit();
          });
        },
        error: (err) => {
          this.isSubmitEnabled = true;
        },
      });
  }

  async preloadAndShowSnackbar(): Promise<void> {
    try {
      await this._imagePreloaderService.preloadImage(this.successImageUrl);
      this.snackbar.openFromComponent(SubmitSuccessComponent, {
        duration: 1500,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: { imageUrl: this.successImageUrl },
      });
    } catch (error) {
      // console.error('Image failed to preload:', error);
    }
  }

  checkMembershipType(value: any) {
    this.membershipTypeStr = value.control.value;
  }

  cancelSubmit() {
    this.dialogRef.close();
  }

  trimStr(formField: any) {
    this.membershipForm.value.formField = formField.control.value.trim();
  }

  ngOnDestroy(): void {
    this.ContactServiceSub$?.unsubscribe();
  }
}
