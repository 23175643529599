import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { StatisticsService } from '../../../shared/services/statistics.service';
import { PlatformService } from '../../../shared/services/platform.service';

@Component({
  selector: 'app-statistics',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './statistics.component.html',
  styleUrl: './statistics.component.scss',
})
export class StatisticsComponent implements OnInit {
  volunteers: number = 0;
  partners: number = 0;
  users: number = 0;
  cases: number = 0;
  inquiries: number = 0;

  constructor(
    private _StatisticsService: StatisticsService,
    private platform: PlatformService
  ) {}

  ngOnInit() {
    if (this.platform.isBrowser) {
      this._StatisticsService.getStatistics().subscribe({
        next: (res: any) => {
          this.volunteers = res.volunteers;
          this.partners= res.partners;
          this.users = res.users;
          this.cases = res.cases;
          this.inquiries = res.inquires;
        },
        error: (err) => {
          // console.log('err: ', err);
        },
      });
    }
  }
}
