<div class="membershipForm-container">
  <div class="close-icon" (click)="cancelSubmit()">
    <img
      loading="lazy"
      src="../../../../assets/images/x-mark_solid.svg"
      alt="close"
    />
  </div>
  <div class="formTitle">انضم معنا كعضو</div>
  <form
    action=""
    class="membershipForm w-100"
    #membershipForm="ngForm"
    id="membershipForm"
  >
    <div class="fields-wrapper">
      <div class="grid-wrapper">
        <!-- Full Name -->
        <div class="field">
          <label class="field__label">
            الإسم الرباعي
            <span class="field__label__required">*</span>
          </label>
          <mat-form-field appearance="outline">
            <input
              class="warning-border"
              matInput
              ngModel
              name="fullName"
              required
              #fullName="ngModel"
              id="fullName"
              pattern="^[a-zA-Z\s\u0600-\u06ff]+$"
              onkeydown="return /[a-zA-Z\s\u0600-\u06ff]/i.test(event.key)"
              placeholder="الإسم الرباعي"
              [TemplateFormValidator]="fullName"
              [validator]="validateFullName"
              appTrim
            />
          </mat-form-field>
          @if( !fullName?.valid && fullName.touched && fullName.errors &&
          fullName.errors['required']){
          <small class="field__alert-message">هذا الحقل مطلوب</small>
          } @if ( fullName.errors && fullName.touched &&
          fullName.errors['pattern']) {
          <small class="field__alert-message"
            >لا يمكن ادخال حروف مميزة او ارقام</small
          >
          } @if ( fullName.errors && fullName.touched &&
          fullName.errors['validator']) {
          <small class="field__alert-message">يجب أن يكون الإسم رباعي</small>
          }
        </div>
        <!-- Birthdate -->
        <div class="field field-date">
          <label class="field__label">
            تاريخ الميلاد
            <span class="field__label__required">*</span>
          </label>
          <mat-form-field
            class="bodytext-normal full-width"
            block
            appearance="outline"
            color="primary"
          >
            <input
              matInput
              [matDatepicker]="datePicker1"
              name="birthdate"
              placeholder="حدد تاريخ الميلاد"
              required
              ngModel
              matInput
              autocomplete="off"
              (click)="datePicker1.open()"
              readonly
              placeholder="اختر"
              [max]="today"
            />
            <mat-datepicker-toggle
              class="custom-suffix"
              matSuffix
              [for]="datePicker1"
            >
              <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #datePicker1></mat-datepicker>
          </mat-form-field>
        </div>
        <!-- Email -->
        <div class="field field-string">
          <label class="field__label">
            البريد الالكترونى
            <span class="field__label__required">*</span>
          </label>
          <mat-form-field appearance="outline">
            <input
              class="warning-border"
              matInput
              ngModel
              name="email"
              #email="ngModel"
              pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
              placeholder="info@ezoah.com"
              required
              appTrim
            />
          </mat-form-field>
          @if( !email?.valid && email.touched && email.errors &&
          email.errors['required']){
          <small class="field__alert-message">هذا الحقل مطلوب</small>
          } @if (email.errors && email.touched && email.errors['pattern']) {
          <small class="field__alert-message"
            >البريد الإلكتروني الذي أدخلته غير صحيح</small
          >
          }
        </div>
        <!-- Gender  -->
        <div class="field field-radio">
          <label class="field__label">
            الجنس
            <span class="field__label__required">*</span>
          </label>
          <mat-select
            ngModel
            name="gender"
            #gender="ngModel"
            required
            placeholder="إختر من القائمة"
          >
            <mat-option value="ذكر">ذكر</mat-option>
            <mat-option value="انثي">أنثى</mat-option>
          </mat-select>
        </div>
        <!-- City  -->
        <div class="field field-radio">
          <label class="field__label"> المدينة </label>
          <mat-select
            ngModel
            name="city"
            #city="ngModel"
            placeholder="إختر من القائمة"
          >
            @for (item of SaudiCities; track $index) {
            <mat-option [value]="item.AR">{{ item.AR }}</mat-option>
            }
          </mat-select>
        </div>
        <!-- Work place -->
        <div class="field">
          <label class="field__label"> جهة العمل الحالية </label>
          <mat-form-field appearance="outline">
            <input
              class="warning-border"
              matInput
              ngModel
              name="currentEmployer"
              #currentEmployer="ngModel"
              placeholder="جهة العمل"
              appTrim
            />
          </mat-form-field>
        </div>
        <!-- Identity number -->
        <div class="field field-nid">
          <label class="field__label">
            رقم الهوية / الإقامة
            <span class="field__label__required">*</span>
          </label>
          <mat-form-field appearance="outline">
            <input
              matInput
              type="number"
              [(ngModel)]="nidNumber"
              required
              name="nid"
              #nid="ngModel"
              [TemplateFormValidator]="nid"
              [validator]="validateSAID"
              inputmode="numeric"
              placeholder="1123456789"
              appTrim
            />
            <!-- [TemplateFormValidator]="nid" [validator]="validateSAID" -->
          </mat-form-field>
          @if( nid.touched && nid.errors && nid.errors['required']){
          <small class="field__alert-message">هذا الحقل مطلوب</small>
          }@else { @if (!nid?.valid && nid.errors && nid.dirty && nid.touched &&
          nid.errors['validator']) {
          <small class="field__alert-message">
            رقم الهوية / الإقامة الذي أدخلته غير صحيح يرجى إدخال الرقم الصحيح
          </small>
          } } @if (nid.errors && nid.touched && nid.dirty &&
          nid.errors['pattern']) {
          <small class="field__alert-message">
            تأكد بأن تكون رقم الهوية / الإقامة باللغه الانجليزية
          </small>
          }
        </div>
        <!-- phone number -->
        <div class="field field-number">
          <label class="field__label">
            رقم الجوال
            <span class="field__label__required">*</span>
          </label>
          <mat-form-field appearance="outline" class="phoneField">
            <!-- //TODO refactor when create dedicated compoennet for phone numbers -->
            <span matSuffix class="d-flex"
              >966+ &nbsp;
              <div class="icon">
                <img
                  loading="lazy"
                  src="../../../../assets/images/SA-flag.svg"
                  alt="رقم الجوال السعودي"
                />
              </div>
            </span>
            <input
              matInput
              [(ngModel)]="phoneNumberShow"
              type="number"
              required
              name="phone"
              #phone="ngModel"
              maxlength="10"
              (keypress)="validNumber(phone)"
              (change)="validNumber(phone)"
              (input)="validNumber(phone)"
              (paste)="validNumber(phone)"
              pattern="^(?!966|00966)[0-9]{9}$"
              inputmode="numeric"
              placeholder="523456789"
              appTrim
            />
          </mat-form-field>
          @if (phone.errors && phone.touched && phone.dirty &&
          !phone.errors['pattern']) {
          <small class="field__alert-message"
            >تأكد بأن يكون رقم الجوال باللغه الانجليزية
          </small>
          } @if (!phone?.valid && phone.touched && phone.errors &&
          phone.errors['required']) {
          <small class="field__alert-message">هذا الحقل مطلوب</small>
          } @if (phone.errors && phone.dirty && phone.touched &&
          phone.errors['pattern']) {
          <small class="field__alert-message"
            >تأكد من إدخال رقم هاتف صحيح
            <br />
            لا تبدأ ب 966 أو 00966
          </small>
          }
        </div>
        <!-- job title -->
        <div class="field">
          <label class="field__label"> المسمى الوظيفي </label>
          <mat-form-field appearance="outline">
            <input
              class="warning-border"
              matInput
              ngModel
              name="jobTitle"
              #jobTitle="ngModel"
              placeholder="المسمى الوظيفي"
              appTrim
            />
          </mat-form-field>
        </div>
      </div>
      <!-- membership type  -->
      <div class="field field-radio">
        <label class="field__label">
          نوع العضوية
          <span class="field__label__required">*</span>
        </label>
        <mat-select
          ngModel
          name="membershipType"
          #membershipType="ngModel"
          required
          placeholder="إختر من القائمة"
          (selectionChange)="checkMembershipType(membershipType)"
        >
          <mat-option value="Worker">عامل</mat-option>
          <mat-option value="Affiliate">منتسب</mat-option>
        </mat-select>
        <!-- <small class="field__hint-message">
                    {{ membershipTypeStr === 'Worker'? 'يتعين سداد رسوم سنوية بقيمة 1000 ريال':
                    membershipTypeStr === 'Affiliate'? 'يتعين سداد رسوم سنوية بقيمة 500 ريال'
                    : '' }}
                </small> -->
      </div>
    </div>
    <small class="field__hint-message">
      {{
        membershipTypeStr === "Worker"
          ? "يتعين سداد رسوم سنوية بقيمة 1000 ريال"
          : membershipTypeStr === "Affiliate"
          ? "يتعين سداد رسوم سنوية بقيمة 500 ريال"
          : ""
      }}
    </small>
    <div class="formSeparator"></div>
    <div class="btns-wrapper">
      <!-- submit button -->
      <button
        mat-button
        (click)="submitMembership()"
        [class.disabledSubmit]="
          membershipForm.status == 'INVALID' || !isSubmitEnabled
        "
        class="submit-btn"
        [disabled]="membershipForm.status == 'INVALID' || !isSubmitEnabled"
        type="submit"
        mat-button
      >
        @if(isSubmitEnabled){ ارسال }@else {
        <app-progress-spinner></app-progress-spinner>
        }
      </button>
      <!-- reset button -->
      <!-- TODO add confirmation dialog to cancel everything (dialogs will be added in shared modules) -->
      <button
        mat-button
        (click)="cancelSubmit()"
        class="cancel-btn"
        type="button"
        mat-button
      >
        الغاء
      </button>
    </div>
  </form>
</div>
