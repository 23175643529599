<div class="about-container">
  <div class="about__section-wrapper">
    <!-- Tabs -->
    <div class="about__tabs-container">
      <div class="heading-3">عن عزوة</div>
      <div class="tabs">
        <mat-tab-group dynamicHeight mat-stretch-tabs="false" mat-align-tabs="center">
          <mat-tab label="رسالتنا">
            <div class="our-msg w-100">
              <div class="sm-text">
                تأسست عزوة بمبادرة من الأستاذ عبداللطيف بن عبدالله الراجحي
              </div>
              <div class="rg-text">
                استجابةً للحاجة المتزايدة للدعم القانوني في قضايا الأحوال الشخصية.
                <br>
                تهدف الجمعية إلى تقديم استشارات قانونية وتمثيل قانوني للأفراد الذين يواجهون صعوبات في فهم حقوقهم أو تحمل
                تكاليف المحامين، من خلال فريق مؤهل ومرخص.
              </div>
            </div>
          </mat-tab>
          <mat-tab label="الهيكل التنظيمي">
            <div class="organization-chart w-100 d-flex">
              <div class="img-holder">
                <img loading="lazy" src="../../../../assets/images/organization-chart.svg" alt="الهيكل التنظيمي" />
              </div>
            </div>
          </mat-tab>
          <mat-tab label="شهادة التسجيل">
            <div class="registration-certificate w-100 d-flex">
              <div class="img-holder">
                <img loading="lazy" src="../../../../assets/images/registration-certificate.webp" alt="شهادة التسجيل" />
              </div>
            </div>
          </mat-tab>
          <mat-tab label="بيانات الإدارة التنفيذية">
            <div class="organization-chart w-100 d-flex">
              <div class="img-holder">
                @if(isMobile){
                  <img loading="lazy" src="../../../../assets/images/Management-data-mobile.svg" alt="بيانات الإدارة التنفيذية" />
                }@else {
                  <img loading="lazy" src="../../../../assets/images/Management-data.svg" alt="بيانات الإدارة التنفيذية" />
                }
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <!-- goals -->
    <div class="about__goals-container">
      <div class="heading-6">أهداف عزوة</div>
      <div class="goals d-flex">
        <div class="goal-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-computer-desktop.svg" alt="التحول الرقمي" />
          </div>
          <div class="title">التحول الرقمي</div>
          <div class="body">
            تحقيق السهولة والمرونة والخصوصية في تقديم الخدمات القانونية
            للمستفيدين.
          </div>
        </div>

        <div class="goal-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-chart-pie.svg" alt="الشراكات التكاملية" />
          </div>
          <div class="title">الشراكات التكاملية</div>
          <div class="body">
            تنسيق الجهود مع الجهات القانونية لتأهيل العاملين وتقديم دعم حقوقي لتمكين المستفيدين من حقوقهم الأسرية.
          </div>
        </div>

        <div class="goal-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-book-open.svg" alt="تنمية الوعي" />
          </div>
          <div class="title">تنمية الوعي</div>
          <div class="body">
            للحد من المخاطر التي تهدد استقرار الأسر وتعزز تماسك المجتمع.
          </div>
        </div>
      </div>
    </div>

    <div class="about__separator"></div>
    <!-- cases -->
    <div class="about__cases-container">
      <div class="header">القضايا التي تعالجها عزوة</div>
      <div class="cases d-flex">
        <div class="case-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-divorce.svg" alt="الطلاق" />
          </div>
          <div class="label">الطلاق</div>
        </div>
        <div class="case-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-forced-divorce.svg" alt="الخلع" />
          </div>
          <div class="label">الخلع</div>
        </div>
        <div class="case-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-newspaper_mini.svg" alt="فسخ النكاح" />
          </div>
          <div class="label">فسخ النكاح</div>
        </div>
        <div class="case-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-children.svg" alt="الحضانة" />
          </div>
          <div class="label">الحضانة</div>
        </div>
        <div class="case-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-home_solid.svg" alt="الزيارة" />
          </div>
          <div class="label">الزيارة</div>
        </div>
        <div class="case-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-banknotes_mini.svg" alt="النفقة" />
          </div>
          <div class="label">النفقة</div>
        </div>
        <div class="case-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-scale_mini.svg" alt="العضل" />
          </div>
          <div class="label">العضل</div>
        </div>
        <div class="case-item d-flex">
          <div class="icon d-flex">
            <img loading="lazy" src="../../../../assets/images/icon-user-group_mini.svg" alt="الإرث" />
          </div>
          <div class="label">الإرث</div>
        </div>
      </div>
    </div>
  </div>
</div>