import { Component, OnInit } from '@angular/core';
import { PlatformService } from '../shared/services/platform.service';
import { DigitalLibraryService } from '../shared/services/digital-library.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Category, materialItem } from '../shared/interfaces/library';
import { MatButtonModule } from '@angular/material/button';
import { ProgressSpinnerComponent } from '../shared/components/progress-spinner/progress-spinner.component';
import { TruncatePipe } from '../shared/pipes/truncate.pipe';
import { PaginationComponent } from '../shared/components/pagination/pagination.component';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { PaginatorSetting } from '../shared/interfaces/paginator-setting';
import { PaginationService } from '../shared/services/pagination.service';
import { CardComponent } from './components/card/card.component';
import { SearchComponent } from './components/search/search.component';

@Component({
  selector: 'app-digital-library',
  standalone: true,
  imports: [
    MatChipsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    ProgressSpinnerComponent,
    TruncatePipe,
    PaginationComponent,
    MatPaginatorModule,
    ProgressSpinnerComponent,
    CardComponent,
    SearchComponent,
  ],
  templateUrl: './digital-library.component.html',
  styleUrl: './digital-library.component.scss',
})
export class DigitalLibraryComponent implements OnInit {
  categories: Category[] = [];
  categoriesCount: number = 0;
  activeCategory!: Category;
  categoryContent!: materialItem[];
  isMobile = false;
  isContentLoading: boolean = true;
  paginatorSetting: PaginatorSetting = {
    length: 0,
    pageSize: 6,
    pages: 1,
    skip: 0,
  };
  isSearching: boolean = false;
  searchKeyword!: string;
  constructor(
    private platform: PlatformService,
    private _DigitalLibraryService: DigitalLibraryService,
    private _pagination: PaginationService
  ) {}

  ngOnInit() {
    if (this.platform.isBrowser) {
      this._DigitalLibraryService.getCategories().subscribe({
        next: (data: any) => {
          this.categories = data;
          this.activeCategory = data[0] ? data[0] : '';
        },
        complete: () => {
          this.viewCategory(this.activeCategory);
        },
      });
      this._DigitalLibraryService
        .getCategoriesCount()
        .subscribe((count: any) => {
          this.categoriesCount = count.count;
        });

      if (document.documentElement.clientWidth <= 768) {
        this.isMobile = true;
      }
      this._pagination.pageSizeObs.subscribe((size) => {
        if (this.activeCategory) {
          this.paginatorSetting.pageSize = size;
          this._pagination.pageIndex.next(0);
          this.getCategoryContentById(this.activeCategory.id, size, 0);
        }
      });

      this._DigitalLibraryService.searchKeywordObs.subscribe((keyword) => {
        if (keyword.length >= 3) {
          this.performSearch(keyword, this.paginatorSetting.pageSize, 0);
          this.isSearching = true;
          this.searchKeyword = keyword;
        } else {
          if (this.isSearching === true) {
            this.isSearching = false;
            this.viewCategory(this.categories[0]);
          }
        }
      });
    }
  }

  viewCategory(category: Category) {
    this.activeCategory = category;
    this.getCategoryContentById(category.id, this.paginatorSetting.pageSize, 0);
  }

  SwapAndViewCategory(category: Category, index: number) {
    this.viewCategory(category);
    if (!this.isMobile) {
      [this.categories[5], this.categories[6 + index]] = [
        this.categories[6 + index],
        this.categories[5],
      ];
    } else {
      [this.categories[1], this.categories[2 + index]] = [
        this.categories[2 + index],
        this.categories[1],
      ];
    }
  }

  getCategoryContentById(id: number, pageSize: number, offset: number) {
    this._DigitalLibraryService.isContentLoading.next(true);
    this.isContentLoading = true;
    const newIndex = Math.floor(offset / pageSize);
    this._pagination.pageIndex.next(newIndex);
    this._DigitalLibraryService.getCategoryCountById(id).subscribe({
      next: (count: any) => {
        this.paginatorSetting.length = count.count;
        this._pagination.emitEvent(this.paginatorSetting.length);
      },
      complete: () => {
        this._DigitalLibraryService
          .getCategoryContentById(id, pageSize, offset)
          .subscribe({
            next: (data: any) => {
              this.categoryContent = data;
            },
            complete: () => {
              this.isContentLoading = false;
              this._DigitalLibraryService.isContentLoading.next(false);
            },
          });
      },
    });
  }

  performSearch(keyword: string, pageSize: number, offset: number) {
    this.isContentLoading = true;
    const newIndex = Math.floor(offset / pageSize);
    this._pagination.pageIndex.next(newIndex);
    this._DigitalLibraryService.searchCount(keyword).subscribe({
      next: (count: any) => {
        this.paginatorSetting.length = count.count;
        this._pagination.emitEvent(this.paginatorSetting.length);
      },
      complete: () => {
        this._DigitalLibraryService
          .searchContent(keyword, pageSize, offset)
          .subscribe({
            next: (data: any) => {
              this.categoryContent = data;
            },
            complete: () => {
              this.isContentLoading = false;
            },
          });
      },
    });
  }

  //get the usercase of this page
  onPageChange(value: any) {
    if (!this.isSearching) {
      this.getCategoryContentById(
        this.activeCategory.id,
        value.size,
        value.index
      );
    } else {
      this.performSearch(this.searchKeyword, value.size, value.index);
    }
    this.scrollIntoView('libraryTitle');
  }

  scrollIntoView(elementId: string) {
    if (elementId != '') {
      const targetElement = document.body.querySelector(`#${elementId}`);
      if (targetElement) {
        const targetElementRect = targetElement.getBoundingClientRect();
        window.scroll({
          behavior: 'smooth',
          top: targetElementRect.top + window.scrollY - 60,
        });
      }
    }
  }
}
